import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from 'vue-router';

const router = createRouter({
  // hash 模式是用 createWebHashHistory()
  history: createWebHistory(),
  routes: [
    {
      path: '/questionnaire/tyjrdj.html',
      component: () => import('@/views/tyjrdj/index.vue'),
    },
  ],
});
export default router;
